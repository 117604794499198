// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/home/circleci/danbydesign/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("/home/circleci/danbydesign/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("/home/circleci/danbydesign/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ourservices-ourservices-js": () => import("/home/circleci/danbydesign/src/pages/ourservices/ourservices.js" /* webpackChunkName: "component---src-pages-ourservices-ourservices-js" */),
  "component---src-pages-portfolio-js": () => import("/home/circleci/danbydesign/src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/home/circleci/danbydesign/.cache/data.json")

